@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-webkit-slider-thumb {
    @apply h-5 w-5 bg-yellow-400 rounded-full;
  }
}
